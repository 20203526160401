import { Observable, of } from "rxjs";
import { BaseFlowComponent } from "../base-flow-component/base-flow-component";
import { CompleteActivityInstallSignatureData } from "./complete-activity-install-signature.data";
import { InstallationComplete } from "../../../../models/device/installation-complete.model";
import { DevicesService } from "../../../../services/devices.service";
import { NotificationService } from "../../../../../core/services/notification.service";
import { tap } from "rxjs/operators";
import { NotificationType } from "../../../../../core/services/notification-type";
import { RequestsService } from "../../../../services/requests.service";
import { Guid } from "guid-typescript";
import { UploadFile } from "../../../../models/attachment";
import { CommentTypeEnum } from "../../../../enums/comment-type.enum";
import { CommentKindEnum } from "../../../../enums/comment-kind.enum";
import { NewCommentModel } from "../../../../models/request/comments/new-comment-model";
import { Component } from "@angular/core";
import { AttachmentType } from "../../../../enums";

@Component({
    selector: 'complete-activity-install-signature',
    templateUrl: './complete-activity-install-signature.component.html',
    styleUrls: ['./complete-activity-install-signature.component.scss',
        '../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class CompleteActivityInstallSignatureComponent 
    extends BaseFlowComponent<CompleteActivityInstallSignatureData>  {
    
    private signatureData: string;

    constructor(
        public requestsService: RequestsService,
        public devicesService: DevicesService,
        public notificationService: NotificationService
    ) {
        super();
    }

    initData() {
    }

    saveChanges(): Observable<any> {
        return of({});
    }

    uploadSignature(data: string) {
        this.signatureData = data;
    }

    finishSuccesfully() {
        this.dataSaving = true;
        let model = new InstallationComplete();
        model.requestId = this.data.summary.requestId;
        model.activityId = this.data.summary.activityId;
        model.devices = this.data.summary.devicesToInstall;
        model.comment = this.data.summary.comment;
        model.finishDateFact = this.data.summary.completedDateTime;
        model.activityResultId = this.data.summary.activityResultId;
        model.activityResultId = this.data.summary.activityResultId;
        model.activityWorkflowTransitionId = this.data.summary.activityWorkflowTransitionId;

        model.devices.forEach(device => {
            if (device.serialNumber != null && device.serialNumber.trim() != '' && device.id != null) {
                device.nomenclature = null;
            }
        });

		this.devicesService.installationComplete(model)
        .pipe(
            tap(() => this.dataSaving = false))
        .subscribe((resp) => {
			if (resp.isSuccessful) {
                this.sendComment();
			} else {
                this.notificationService.error({
					title: 'Ошибка при успешном завершении активности',
					message: resp.errorDescription,
					notificationType: NotificationType.Toast
				});
            }
		});
    }

    private sendComment() {
        let file = new UploadFile;
		file.fileName = `confirm-signature_${this.data.summary.requestId}_${this.data.summary.activityId}.jpg`;
		file.fileExtension = '.jpg';
		file.uid = Guid.create().toString();
		file.attachmentTypeIds = [AttachmentType.merchantMemberSignature]
		file.attachmentTypeId = AttachmentType.merchantMemberSignature;
		file.fileData = this.signatureData;
		
		this.requestsService.addComment({
			requestId: this.data.summary.requestId,
			activityId: this.data.summary.activityId,
			text: `Работу принял представитель ТСП ${this.data.merchantMember}`,
			uploadAttachments: [file],
			commentType: CommentTypeEnum.user,
			commentKind: CommentKindEnum.internal
		} as NewCommentModel).subscribe(() => {
            this.dataSaving = false;
			this.goToNext();
		});
    }
}